import React from 'react'
import { Link } from "gatsby"
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

import '../styles/global.css'

import styled from "styled-components"

const noteversion = ({locale,location}) => {
    return(
        <BodyContainer>
            <MainContainer>
                <div><Link to="/" style={{color:'rgb(23, 21, 21)',textDecoration:'underline'}}>{"< Accueil"}&nbsp;</Link></div>
                <div style={{textAlign:'center', padding: '50px 0px 50px 0px'}}>
                    <h2>Note de version - 2024</h2>
                    <p>
                    Nouvelle année, nouvelle application.<br/>
                    C’est une mise à jour importante du jeu.<br/><br/>

                    Pendu du Design évolue dans une nouvelle forme de jeu, Voici les changements.<br/><br/>

                    Les publications sont désormais :<br/>
                    - Éphémères. Elles apparaissent dans un temps limité. C’est un événement.<br/>
                    - Chronométré. Vous avez un chronomètre qui indique la prochaine mise à jour du jeu.<br/>
                    - Multi-jeu. Elles peuvent parfois contenir uniquement des histoires textuelles ou des vidéos, des jeux de pendu mais également d’autres types de jeu ou de contenu.<br/>
                    - Payante ou gratuite. La seule manière de les conserver est de les acheter. Dans ce cas, elles seront toujours disponibles pour vous dans l’écran Publications.<br/><br/>

                    Les publications sur les Styles vol.1, Styles vol.2 et les Signes de l’Empire vont se réafficher dans l’écran publication très prochainement. <br/>
                    Les parcours sont en train d’être mis à jour. Profitez de l’événement en cours, en attendant.<br/><br/>

                    Activez vos notifications pour le prochain événement !<br/><br/>
                    </p>
                </div>
                <div><Link to="/" style={{color:'rgb(23, 21, 21)',textDecoration:'underline'}}>{"< Accueil"}&nbsp;</Link></div>
            </MainContainer>
        </BodyContainer>
    )
}

export const Head = () => {
    return <meta name="robots" content="noindex, nofollow" />
}

const BodyContainer = styled.div`
    background-color:white;
    width:100%;
    height:100%;
    color:rgb(23, 21, 21);
`

const MainContainer = styled.div`
    max-width:600px;
    margin:auto;
    padding:50px;
    background-color:'white';
`

export default noteversion